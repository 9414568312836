import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipeline_name: string;
  description: string;
  permission_level: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatePipelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPipelineApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipeline_name: '',
      description: '',
      permission_level: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.getPipelineCreatedData(message)
    // Customizable Area End
  }
  // Customizable Area Start

  isFormComplete = () => {
    const { pipeline_name, description, permission_level } = this.state;
    return (
      pipeline_name &&
      description &&
      permission_level
    );
  };

  async componentDidMount() {
    super.componentDidMount()
  }

  handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleCreatePipeline = (event: any) => {
    event.preventDefault()
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const { pipeline_name, description, permission_level } = this.state
    const httpBody = {
      pipeline: {
        pipeline_name,
        description,
        permission_level
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPipelineApiId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPipelineApiEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getPipelineCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.createPipelineApiId) {
        if (responseJson.pipeline_name) {
          this.props.navigation.navigate('PipelineDetail', { pipelineId: responseJson.id })
        }
      }
    }
  }
  // Customizable Area End
}
