import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stage_name: string,
  description: string,
  permission_level: string,
  isModalOpen: boolean,
  isEditModalOpen: boolean,
  editStageValue: string,
  filterPipeline: any,
  pipeline_id: string,
  stages: string[],
  stageId: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PipelineDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pipelineApiCallId: string = ''
  createStageApiCallId: string = ''
  getStageApiCallId: string = ''
  editStageApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stage_name: '',
      description: '',
      permission_level: '',
      isModalOpen: false,
      isEditModalOpen: false,
      editStageValue: '',
      filterPipeline: [],
      pipeline_id: '',
      stages: [],
      stageId: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.getSinglePipelineDataValue(message)
    this.getStageCreatedData(message)
    this.getStageData(message)
    this.getEditedStageDataValue(message)
    // Customizable Area End
  }

  // Customizable Area Start

  handleClose = () => {
    this.toggleModal();
  };

  handleEditClose = () => {
    this.toggleEditModal()
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  toggleEditModal = () => {
    this.setState((prevState) => ({ isEditModalOpen: !prevState.isEditModalOpen }));
  };

  handleAddStage = (event: any) => {
    event.preventDefault()
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const StageHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const { stage_name, description, permission_level } = this.state
    const httpBody = {
      stage: {
        stage_name,
        description,
        permission_level,
        pipeline_id: this.state.filterPipeline[0]?.id
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createStageApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stageApiEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(StageHeader));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  };

  getStageCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.createStageApiCallId) {
        this.setState({ permission_level: '', stage_name: '', description: '' })
        this.toggleModal()
        this.getStageApiCall()
      }
    }
  }
  getStageApiCall = () => {
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const StageHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStageApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stageApiEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(StageHeader));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  };

  getStageData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getStageApiCallId) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const pipelineId = urlParams.get('pipelineId');
        const filteredStages = responseJson.filter((item:any)=>item.pipeline_id==pipelineId)
        this.setState({ stages: filteredStages })
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount()
    this.getAllPipelineData()
    this.getStageApiCall()
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    } as S));
  };

  handleEditStageToggle = (item: any) => {
    this.toggleEditModal()
    this.setState({ stageId: item.id,editStageValue:item.stage_name})
  }
  handleEditStage = (event: any) => {
    event.preventDefault()
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const StageEditHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const { editStageValue } = this.state
    const httpBody = {
      stage: {
        stage_name: editStageValue,
        pipeline_id: this.state.filterPipeline[0]?.id
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editStageApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stageApiEndPoint}/${this.state.stageId}/`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(StageEditHeader));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getEditedStageDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.editStageApiCallId) {
        this.setState({ editStageValue: '' })
        this.toggleEditModal()
        this.getStageApiCall()
      }
    }
  }

  getAllPipelineData = () => {
    let tokenValue = "";
    if (typeof localStorage !== 'undefined') {
      tokenValue = localStorage.getItem("token") || "";
    }
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenValue
    };
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.pipelineApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPipelineDataEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getSinglePipelineDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.pipelineApiCallId) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const pipelineId = urlParams.get('pipelineId');
        if (responseJson && responseJson.errors) {
          this.setState({ filterPipeline: [] });
        } else if (Array.isArray(responseJson)) {
          const filterPipeline = responseJson.filter(item => item.id == pipelineId)
          this.setState({ filterPipeline });
        } else {
          this.setState({ filterPipeline: [] });
        }
      }
    }
  }
  handleCreateRule=()=>{
    this.props.navigation.navigate('CreatePipelineRule')
  }
  // Customizable Area End
}
