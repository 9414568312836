import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { styled } from "@mui/styles";
import { Box, TextField, MenuItem, Typography, Divider, Radio, FormControlLabel } from '@mui/material';
// Customizable Area End

import CreatePipelineController, {
    Props,
} from "./CreatePipelineController";

export default class CreatePipeline extends CreatePipelineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const permissionsData = [{ label: "Level 01", value: "level1" }, { label: "Level 02", value: "level2" }, { label: "Level 03", value: "level3" }]
        return (

            <CreatePipelineContainer>
                <TopNav navigation={this.props.navigation} id="pipelines" />
                <Divider sx={dividerStyle} />
                <Title>Create New Pipeline</Title>
                <Box component="form" noValidate autoComplete="off" onSubmit={this.handleCreatePipeline}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Pipeline Name
                    </Typography>
                    <TextField
                        name="pipeline_name"
                        data-test-id="pipeline-name"
                        value={this.state.pipeline_name}
                        onChange={this.handleChangeValue}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline name"
                        style={{ marginBottom: "0.8rem" }}
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem',
                                fontWeight: 400,
                                fontSize: '1.6rem'
                            },
                        }}
                    />

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Description
                    </Typography>
                    <TextField
                        name="description"
                        data-test-id="pipeline-desc"
                        value={this.state.description}
                        onChange={this.handleChangeValue}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline description"
                        style={{ marginBottom: "0.8rem" }}
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem 4rem 0.8rem',
                                fontWeight: 400,
                                fontSize: '1.6rem'
                            },
                        }}
                    />

                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                        Permissions
                    </Typography>
                    <TextField
                        name="permission_level"
                        data-test-id="permission-level"
                        value={this.state.permission_level || ''}
                        onChange={this.handleChangeValue}
                        select
                        fullWidth
                        inputProps={{
                            sx: {
                                padding: '1rem 0.8rem',
                                fontWeight: 400,
                                fontSize: "1.6rem",
                                display: 'flex',
                                alignItems: 'center'
                            },
                        }}
                        InputLabelProps={{ shrink: false }}
                        SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected: unknown) => {
                                if (!selected) {
                                    return <span style={{ color: "#999" }}>select</span>;
                                }
                                return selected as string;
                            },
                        }}
                    >
                        {permissionsData.map((item, index) => (
                            <MenuItem key={index} value={item.value} sx={{
                                fontSize: '1.6rem', fontWeight: 400, backgroundColor: this.state.permission_level === item.value ? '#1A4A421A' : 'white',
                                '&:hover': {
                                    backgroundColor: this.state.permission_level === item.value ? '#1A4A421A' : '#f0f0f0',
                                },
                            }}>
                                <FormControlLabel
                                    value={item}
                                    control={
                                        <Radio
                                            checked={this.state.permission_level === item.value}
                                            onChange={this.handleChangeValue}
                                            value={item.value}
                                            name="permission_level"
                                            sx={{
                                                padding: '0 0.8rem',
                                                '&.Mui-checked': {
                                                    color: '#1A4A42',
                                                },
                                                '&.MuiRadio-root': {
                                                    color: '#1A4A42',
                                                },
                                                '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                                                    backgroundColor: '#1A4A421A',
                                                },
                                            }}
                                        />
                                    }
                                    label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                                        {item.label}
                                    </Typography>}
                                    sx={{
                                        fontSize: '1.6rem',
                                        fontWeight: '400'
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </TextField>
                    <WarningBox>
                        <StartButton type="submit" disabled={!this.isFormComplete()} style={{
                            backgroundColor: this.isFormComplete() ? "#1A4A42" : "#A8A29E66",
                            color: this.isFormComplete() ? "#fff" : "#78716C",
                            cursor: this.isFormComplete() ? "pointer" : "not-allowed",
                        }}>Start Pipeline</StartButton>
                        <WarningText>*You are creating a pipeline</WarningText>
                    </WarningBox>
                </Box>
            </CreatePipelineContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const CreatePipelineContainer = styled("div")({
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
        padding: "1.5rem",
    },
    "@media (max-width: 400px)": {
        padding: "1rem",
    },
});

const dividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const Title = styled("h1")({
    fontSize: "2rem",
    fontWeight: 600,
    margin: "2rem 0",
    color: "#292524",
});

const WarningBox = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    border: "0.1rem dashed #292524",
    padding: "1.5rem",
    borderRadius: "1.2rem",
    backgroundColor: "#f9f9f9",
    marginTop: "2.5rem"
});

const StartButton = styled("button")({
    width: "100%",
    padding: "1rem",
    border: "none",
    borderRadius: "0.8rem",
    fontSize: "1.6rem",
    cursor: "pointer",
    fontWeight: 600,
});

const WarningText = styled("span")({
    alignSelf: "flex-end",
    fontSize: "1.4rem",
    color: "#737373",
    fontWeight: 400,
    fontStyle: "italic",
});
// Customizable Area End
