import React from "react";
// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import { styled } from "@mui/styles";
// Customizable Area End

import CreatePipelineRuleController, {
  Props,
} from "./CreatePipelineRuleController";

export default class PipelineRule extends CreatePipelineRuleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <PipelineRuleContainer>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider />
        <p>Rule Builder</p>
      </PipelineRuleContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PipelineRuleContainer = styled("div")({
  padding: "20px 20px 10px",
  borderRadius: "12px",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0px 2px 8px 0px",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    padding: "15px",
  },
  "@media (max-width: 400px)": {
    padding: "10px",
  },
});

const Divider = styled("hr")({
  width: "100%",
  margin: "15px 0",
  backgroundColor: "#1A4A4233",
  color: "#1A4A4233"
});
// Customizable Area End
